<template>
	<div class="container">
		<div class="f-center font48 black bold" style="height: 950px;">
			暂无网络
		</div>
		<i @click.stop="$router.replace('/index/quCode?type=sys&net=no')" class="el-icon-s-tools shezhi"></i>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				
			}
		},
		mounted() {
			
		},
		methods: {
			
		}
	};
</script>
<style lang="scss" scoped>
	.shezhi {
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: 48px;
		color: #666;
	}
	@media screen and (max-width: 1080px) {
		.net-box {
			width: 100%;
			background: #ffffff;
			border-radius: 50px 50px 0px 0px;
		}
	}
	
	@media screen and (min-width: 1100px) {
		
	}
</style>

